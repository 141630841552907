import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store, {key} from './store';
import vuetify from './plugins/vuetify';
import {loadFonts} from './plugins/webfontloader';
import {i18n} from './plugins/i18n';
import {useApi} from '@/api';
import {authenticateRouter} from '@/plugins/auth';
import axios from 'axios';
import {WsPlugin} from '@/plugins/websockets';

loadFonts();

authenticateRouter(router, store);

/* intercept responses to logout user */
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            store.commit('authentication/setAuthenticated', false);
            store.commit('authentication/setAuthenticatedUser', null);
            router.push('login');
        }
    });

useApi().api.auth.currentUser().then(async (response) => {
    const isAuthenticated = (response && response.success && response.data) || false;
    store.commit('authentication/setAuthenticated', !!isAuthenticated);
    if (isAuthenticated && response) {
        store.commit('authentication/setAuthenticatedUser', response.data);
    }
    createApp(App).use(i18n)
        .use(router)
        .use(store, key)
        .use(vuetify)
        .use(WsPlugin)
        .mount('#app');
});

