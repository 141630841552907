import {IWebsocketMessage, MessageType} from '@/websocket/websocketmessage';

export class SubscribeMessage implements IWebsocketMessage {
    public readonly messageType = MessageType.SUBSCRIBE;
    public readonly recipientChannel = '';
    public readonly value: string;


    constructor(value: string[]) {
        this.value = JSON.stringify(value);
    }
}
