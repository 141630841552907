import {IWebsocketMessage, MessageType} from '@/websocket/websocketmessage';

export class UnsubscribeMessage implements IWebsocketMessage {
    public readonly messageType = MessageType.UNSUBSCRIBE;
    public readonly recipientChannel = '';
    public readonly value: string;


    constructor(value: string[]) {
        this.value = JSON.stringify(value);
    }
}
