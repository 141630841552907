import {useApi} from '@/api';
import store from '@/store';
import {AxiosError} from 'axios';
import {WrapperResponse} from '@/api/generic';
import {User} from '@/models/User';
import {$t} from '@/plugins/i18n';
import {MessageService} from '@/composables/ShowsMessages';

export class AuthenticationService {
    private readonly msgService: MessageService;

    constructor() {
        this.msgService = new MessageService(store);
    }

    public async login(username: string, password: string) {
        const {api} = useApi();
        let response: WrapperResponse<User> | null = null;
        let message = $t('message.genericError') as string;
        try {
            response = await api.auth.login(username, password);
        } catch (e) {
            if (e instanceof AxiosError && e.response) {
                switch (e.response.status) {
                case 401:
                    message = $t('LoginPage.messages.loginFailed') as string;
                    break;
                case 403:
                    if (e.response.data?.error?.message === 'TooManyLoginAttempts') {
                        message = $t('LoginPage.messages.TooManyLoginAttempts') as string;
                    }
                    break;
                default:
                    message = $t('message.genericError') as string;
                }
            }
            this.msgService.showError(message);
        }

        if (response && response.success && response.data) {
            store.commit('authentication/setAuthenticated', true);
            store.commit('authentication/setAuthenticatedUser', response.data);
            return true;
        }
        return false;
    }

    public async logout() {
        const {api} = useApi();

        try {
            await api.auth.logout();
        } catch (e) {
            // ignore error
        } finally {
            await store.dispatch('authentication/logout');
        }
    }
}

export function useAuthentication() {
    return {
        authService: new AuthenticationService(),
    };
}
