import {App} from 'vue';
import {url} from '@/utils/http';
import {WebsocketService} from '@/websocket/websocketservice';

const wsServiceSymbol = Symbol('$ws');

const WsPlugin = {
    install(app: App) {
        injectWebsocketService(app);
    },
};

export function injectWebsocketService(app: App) {
    const websocketService = new WebsocketService(url('/socket').replace('http', 'ws'));
    app.config.globalProperties.$ws = websocketService;
    app.provide(wsServiceSymbol, websocketService);
    return websocketService;
}

export {
    wsServiceSymbol,
    WsPlugin,
};
