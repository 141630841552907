export type SortDirection = 'DESC' | 'ASC';

export interface Sort {
    sort: string;
    direction: SortDirection;
}

export interface Pagination {
    page: number;
    size: number;
    sort?: Sort;
}

export class PaginationImpl implements Pagination {

    public static serialize(pagination: Pagination) {
        const params = new URLSearchParams();
        params.append('page', String(pagination.page));
        params.append('size', String(pagination.size));
        if (pagination.sort && !!pagination.sort.sort) {
            params.append('sort', `${pagination.sort.sort},${pagination.sort.direction}`);
        }
        return params;
    }

    public page = 0;
    public size = 20;
    public sort?: Sort;

    constructor(page: number, size: number, sort = 'id', direction: SortDirection = 'DESC') {
        this.page = page;
        this.size = size > 0 ? size : 1000; // Limit to 1000.
        this.sort = {sort, direction};
    }

    public serialize(): URLSearchParams {
        return PaginationImpl.serialize(this);
    }
}
