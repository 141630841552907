import {IWebsocketMessage, MessageType} from '@/websocket/websocketmessage';
import {SubscribeMessage} from '@/websocket/subscribemessage';
import {UnsubscribeMessage} from '@/websocket/unsubscribemessage';

/* eslint-disable @typescript-eslint/no-explicit-any */

export interface IWebsocketService {
    readonly isConnected: boolean;

    addEventHandler(event: WebsocketServiceEvent, handler: (event: any) => void): void;

    addEventHandler(event: 'message', handler: (event: IWebsocketMessage) => void): void;

    removeEventHandler(event: WebsocketServiceEvent, handler: (event: any) => void): void;

    addSubscribedEventHandler(channel: string, handler: (event: IWebsocketMessage) => void): () => void;

    removeSubscribedEventHandler(channel: string, handler: (event: IWebsocketMessage) => void): void;

    waitForConnected(): Promise<void>;

    disconnect(): Promise<void>;

    connect(workstationKey: string): Promise<void>;

    sendMessage(message: IWebsocketMessage): void;

    sendPostMessage(channel: string, message: unknown): void;

    /**
     * Subscribe to the given channels, if not already subscribed to them.
     * The channels are reference counted.
     *
     * @param channels list of channels to subscribe to
     */
    subscribe(channels: string[]): void;

    /**
     * Unsubscribe the given channels, if no other claims were made to the channels.
     * The channels are reference counted.
     *
     * @param channels the channels to unsubscribe for
     */
    unsubscribe(channels: string[]): void;
}

export class WebsocketService implements IWebsocketService {
    private shouldReconnect = false;
    private readonly url: string;
    private webSocket: WebSocket | undefined;
    private eventHandlers = new Map<WebsocketServiceEvent, Array<((event: any) => void)>>();
    private subscribedMessageEventHandlers = new Map<string, Array<((event: any) => void)>>();
    private subscribeCounter = new Map<string, number>();
    private retryTimeoutTime = 0;

    public get isConnected() {
        return this.webSocket?.readyState === WebSocket.OPEN;
    }

    constructor(url: string) {
        this.url = url;
    }

    public addEventHandler(event: WebsocketServiceEvent, handler: (event: any) => void) {
        const handlers = this.eventHandlers.get(event) || [];
        handlers.push(handler);
        this.eventHandlers.set(event, handlers);
    }

    public removeEventHandler(event: WebsocketServiceEvent, handler: (event: Event) => void) {
        const handlers = this.eventHandlers.get(event) || [];
        const index = handlers.indexOf(handler);
        if (index !== -1) {
            handlers.splice(index, 1);
        }
        this.eventHandlers.set(event, handlers);
    }

    public addSubscribedEventHandler(channel: string, handler: (event: IWebsocketMessage) => void): () => void {
        const handlers = this.subscribedMessageEventHandlers.get(channel) || [];
        handlers.push(handler);
        this.subscribedMessageEventHandlers.set(channel, handlers);
        this.subscribe([channel]);

        return () => {
            this.removeSubscribedEventHandler(channel, handler);
        };
    }

    public removeSubscribedEventHandler(channel: string, handler: (event: IWebsocketMessage) => void): void {
        const handlers = this.subscribedMessageEventHandlers.get(channel) || [];
        const index = handlers.indexOf(handler);
        if (index !== -1) {
            handlers.splice(index, 1);
        }
        this.subscribedMessageEventHandlers.set(channel, handlers);
        this.unsubscribe([channel]);
    }

    public async waitForConnected() {
        return new Promise<void>((resolve) => {
            if (this.webSocket?.readyState === WebSocket.OPEN) {
                resolve();
            } else {
                const fn = () => {
                    resolve();
                    this.removeEventHandler('open', fn);
                };
                this.addEventHandler('open', fn);
            }
        });
    }

    public async disconnect() {
        this.shouldReconnect = false;
        if (this.webSocket != null) {
            this.webSocket.close();
        }
    }

    public async connect(workstationKey: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.shouldReconnect = true;
            let resolveOrRejected = false;
            const url = new URL(this.url);
            url.searchParams.append('workstation_key', workstationKey);
            this.webSocket = new WebSocket(url.href);
            this.webSocket.onerror = (event) => {
                if (!resolveOrRejected) {
                    resolveOrRejected = true;
                    reject(event);
                }
                this.onError(event);
            };
            this.webSocket.onopen = (event) => {
                if (!resolveOrRejected) {
                    resolveOrRejected = true;
                    resolve();
                }
                this.onOpen(event);

                // resubscribe for the channels we were subbed to
                const channels = Array.from(this.subscribeCounter.keys());
                // we do not call the method here because we dont want to account this
                const message = new SubscribeMessage(channels);
                this.webSocket?.send(JSON.stringify(message));
                this.retryTimeoutTime = 0;
            };
            this.webSocket.onmessage = (event) => this.onMessage(event);
            this.webSocket.onclose = (event) => {
                this.onClose(event);

                if (this.shouldReconnect) {
                    // tslint:disable-next-line:no-console
                    console.warn(`Websocket connection closed, retrying in ${(this.retryTimeoutTime / 1000).toFixed(1)} seconds ...`);
                    setTimeout(() => this.connect(workstationKey), this.retryTimeoutTime);
                    this.retryTimeoutTime = 2000;
                }
            };
        });
    }

    public sendMessage(message: IWebsocketMessage) {
        if (!this.webSocket || this.webSocket.readyState !== this.webSocket.OPEN) {
            throw new Error('Cannot send message when no connection open');
        }

        this.webSocket.send(JSON.stringify(message));
    }

    public sendPostMessage(channel: string, message: unknown) {
        if (!this.webSocket || this.webSocket.readyState !== this.webSocket.OPEN) {
            throw new Error('Cannot send message when no connection open');
        }

        this.sendMessage({
            messageType: MessageType.POST_WORKSTATION,
            recipientChannel: channel,
            value: JSON.stringify(message),
        });
    }

    public subscribe(channels: string[]) {
        const message = new SubscribeMessage(channels);
        if (this.webSocket?.readyState === WebSocket.OPEN) {
            this.webSocket.send(JSON.stringify(message));
        }

        for (const channel of channels) {
            let count = this.subscribeCounter.get(channel) || 0;
            this.subscribeCounter.set(channel, ++count);
        }
    }

    public unsubscribe(channels: string[]) {
        const message = new UnsubscribeMessage(channels);
        if (this.webSocket?.readyState === WebSocket.OPEN) {
            this.webSocket.send(JSON.stringify(message));
        }

        for (const channel of channels) {
            const count = (this.subscribeCounter.get(channel) || 1) - 1;
            if (count > 0) {
                this.subscribeCounter.set(channel, count);
            } else {
                this.subscribeCounter.delete(channel);
            }
        }
    }

    private onMessage(event: MessageEvent) {
        if (event.data) {
            const message = JSON.parse(event.data) as IWebsocketMessage;
            this.callHandlers('message', message);

            if (message.messageType === MessageType.CLIENT_INFO_REQUEST) {
                this.sendMessage({
                    messageType: MessageType.CLIENT_INFO_RESPONSE,
                    recipientChannel: 'client_info',
                    value: JSON.stringify({
                        client: 'workstation',
                        hostname: '?',
                        version: `${process.env.VUE_APP_VERSION ?? '?'}`,
                    }),
                });
            }
            const messageHandlers = this.subscribedMessageEventHandlers.get(message.recipientChannel) || [];
            messageHandlers.forEach((handler) => {
                handler(message);
            });
        }
    }

    private onError(event: Event) {
        this.callHandlers('error', event);
    }

    private onOpen(event: Event) {
        this.callHandlers('open', event);
    }

    private onClose(event: Event) {
        this.callHandlers('close', event);
    }

    private callHandlers(eventName: WebsocketServiceEvent, parameter: Event | IWebsocketMessage) {
        const handlers = this.eventHandlers.get(eventName) || [];
        handlers.forEach((handler) => {
            handler(parameter);
        });
    }
}

export type WebsocketServiceEvent = 'open' | 'error' | 'message' | 'close';
