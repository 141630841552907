import {createI18n, TranslateResult} from 'vue-i18n';
import {get, http} from '@/utils/http';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const fallback = require('../../public/locale/messages.nl.json');

const i18n = createI18n({
    legacy: false,
    locale: 'nl', // set locale
    fallbackLocale: 'nl', // set fallback locale
    messages: {nl: fallback}, // set locale messages
});


type tType = <Key>(key: Key, named?: Record<string, unknown>) => TranslateResult;
const {t: $t} = i18n.global as unknown as { t: tType };
export {
    i18n,
    $t,
};

const loadedLocales = ['nl'];

export function setI18nLocale(locale: string): string {
    i18n.global.locale = locale as never;
    http.defaults.headers.common['Accept-Language'] = locale;
    document.querySelector('html')?.setAttribute('lang', locale);

    // dayjslocale(locale); TODO set dayjs locale when it's included

    return locale;
}

export async function loadI18nMessages(locale: string) {
    locale = locale.toLowerCase().replace(/[^a-z]/g, '');
    if (locale === i18n.global.locale.value) {
        return Promise.resolve(setI18nLocale(locale));
    }

    if (loadedLocales.indexOf(locale) !== -1) {
        return Promise.resolve(setI18nLocale(locale));
    }

    try {
        const response = await get(`/locale/messages.${locale}.json`);
        let messages: {[key: string]: string } | null = null;
        if (typeof response.data === 'string') {
            messages = JSON.parse(response.data);
        } else if (typeof response.data === 'object') {
            messages = response.data;
        }

        if (messages) {
            i18n.global.setLocaleMessage(locale, messages);
            loadedLocales.push(locale);
            setI18nLocale(locale);
        }
    } catch (e) {
        // intentionally left empty
    }
}

