import {useApi} from '@/api';
import {onMounted, ref} from 'vue';

export function useAppConfiguration() {
    const {api} = useApi();

    // default to Infinity, which will basically never highlight the result
    const maxResultPeriodSec = ref<number>(Infinity);

    onMounted(async () => {
        await loadConfigData();
    });

    async function loadConfigData() {
        const {data} = await api.config.maxResultPeriodLabSubmission();
        // default to Infinity, which will basically never highlight the result
        maxResultPeriodSec.value = data ?? Infinity;
    }

    return {
        loadConfigData,
        maxResultPeriodSec,
    };
}
