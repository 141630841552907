import {inject, onMounted, onUnmounted} from 'vue';
import {IWebsocketService} from '@/websocket/websocketservice';
import {IWebsocketMessage, MessageType} from '@/websocket/websocketmessage';
import _toPairs from 'lodash/toPairs';
import {wsServiceSymbol} from '@/plugins/websockets';
import {useMessages} from '@/composables/ShowsMessages';
import {$t} from '@/plugins/i18n';

export interface WebsocketConfig {
    channels: { [key: string]: (event: IWebsocketMessage) => void };
    noWorkstationWarning?: boolean;
}

let lastWarningTimestamp = 0;

export function useWebsocket(config?: WebsocketConfig) {
    const ws = inject(wsServiceSymbol) as IWebsocketService;
    const cleanupCallbacks: Array<() => void> = [];

    const subscribe = (channel: string, handler: (event: IWebsocketMessage) => void) => {
        cleanupCallbacks.push(ws.addSubscribedEventHandler(channel, handler));
    };

    onMounted(() => {
        if (config?.channels) {
            if (!(config.noWorkstationWarning ?? false)) {
                showWarningIfNoWorkstationKey();
            }
            for (const [channel, handler] of _toPairs(config.channels)) {
                subscribe(channel, handler);
            }
        }
    });

    onUnmounted(() => {
        for (const cleanupCallback of cleanupCallbacks) {
            cleanupCallback();
        }
    });

    (window as any)._barcode = (string: string) => {
        if (config?.channels?.barcode) {
            config.channels.barcode({
                value: JSON.stringify({
                    code: string,
                }),
                messageType: MessageType.POST_WORKSTATION,
                recipientChannel: 'barcode',
            });
        }
    };

    const {showMessage} = useMessages();
    const showWarningIfNoWorkstationKey = () => {
        if (!localStorage.getItem('workstationKey') && Date.now() - lastWarningTimestamp > 300_000) {
            showMessage($t('message.noWorkstationKey'), {timeout: 15000});
            lastWarningTimestamp = Date.now();
        }
    };

    return {
        subscribe,
        ws,
    };
}
