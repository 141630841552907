import {GenericApi, WrapperResponse} from "@/api/generic";
import {ShippingBox} from "@/models/ShippingBox";
import {get, PathVariableMap, url} from "@/utils/http";
import {ShippingBoxDetails} from "@/models/ShippingBoxDetails";
import {LabOrderStatus} from "@/models/LabOrderStatus";
import {LabOrder} from "@/models/LabOrder";

export class ShippingBoxApi extends GenericApi<ShippingBox> {

    constructor() {
        super('/api/v1/shipping-box');
    }

    public async listActiveOrPending(pathVariables: PathVariableMap = {}) {
        const uri = new URL(url(this.path, pathVariables));
        const result = await get(uri.href);
        return result.data as WrapperResponse<ShippingBox[]>;
    }

    public async findLabOrdersByStatus(shippingBox: ShippingBox, status: LabOrderStatus, pathVariables: PathVariableMap = {}) {
        const uri = new URL(url(`${this.path}/${shippingBox.id}/lab-orders`, pathVariables));
        const searchParams = uri.searchParams;
        searchParams.set('status', status.toString());
        const result = await get(uri.href);
        return result.data as WrapperResponse<LabOrder[]>;
    }

    public async details(shippingBox: ShippingBox, pathVariables: PathVariableMap = {}) {
        const uri = new URL(url(`${this.path}/${shippingBox.id}/details`, pathVariables));
        const result = await get(uri.href);
        return result.data as WrapperResponse<ShippingBoxDetails>;
    }
}
