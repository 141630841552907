import {GenericApi, WrapperResponse} from "@/api/generic";
import {ShippingBox} from "@/models/ShippingBox";
import {get, PathVariableMap, url} from '@/utils/http';
import {LabOrder} from '@/models/LabOrder';
import {LabMeasurement} from "@/models/LabMeasurement";

export class ConfigApi extends GenericApi<string> {

    constructor() {
        super('/api/v1/config');
    }

    public async maxResultPeriodLabSubmission() {
        const uri = new URL(url(`${this.path}/max-result-period-lab-submissions`));
        const result = await get(uri.href);
        return result.data as WrapperResponse<number>;
    }
}
