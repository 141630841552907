import {Pagination, PaginationImpl} from '@/utils/api-tools/pagination';
import {PaginationResponse} from '@/utils/api-tools/pagination-response';
import {ISearchCriterion, SearchCriterion} from '@/utils/api-tools/search-criteria';
import {get, mergeURLSearchParams, PathVariableMap, post, put, remove, url} from '@/utils/http';

export interface WrapperResponse<T> {
    success: boolean;
    data: T | null;
}

export class GenericApi<T> {
    public path: string;

    constructor(path: string) {
        this.path = path;
    }

    public async list(pagination: Pagination, search: ISearchCriterion[], include: string[] = [],
        pathVariables: PathVariableMap = {}):
        Promise<WrapperResponse<PaginationResponse<T>>> {
        const uri = new URL(url(this.path, pathVariables));
        const paginationParams = PaginationImpl.serialize(pagination);
        const searchParams = search.reduce((prev: URLSearchParams, curr: ISearchCriterion) => {
            return mergeURLSearchParams(prev, SearchCriterion.serialize(curr));
        }, new URLSearchParams());
        mergeURLSearchParams(uri.searchParams, paginationParams, searchParams,
            new URLSearchParams(include.map((item) => ['include', item])));

        const result = await get(uri.href);
        return result.data as WrapperResponse<PaginationResponse<T>>;
    }

    public async listAll(search: ISearchCriterion[] = [], include: string[] = [], pathVariables: PathVariableMap = {}):
        Promise<WrapperResponse<PaginationResponse<T>>> {
        return this.list(new PaginationImpl(0, -1), search, include, pathVariables);
    }

    public async get(id: number | string, pathVariables: PathVariableMap = {}) {
        const uri = new URL(url(this.path + `/${id}`, pathVariables));
        const result = await get(uri.href);
        return result.data as WrapperResponse<T>;
    }

    public async save(model: Partial<T>, pathVariables: PathVariableMap = {}) {
        const uri = new URL(url(this.path, pathVariables));
        const result = await put(uri.href, model);
        return result.data as WrapperResponse<T>;
    }

    public async create(model: Partial<T>, pathVariables: PathVariableMap = {}) {
        const uri = new URL(url(this.path, pathVariables));
        const result = await post(uri.href, model);
        return result.data as WrapperResponse<T>;
    }

    public async delete(modelId: number | string, pathVariables: PathVariableMap = {}) {
        const uri = new URL(url(this.path, pathVariables) + `/${modelId}`);
        const response = await remove(uri.href);
        return {success: response.status >= 200 && response.status < 300} as WrapperResponse<never>;
    }
}
