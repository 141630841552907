import {Idable} from "@/models/Idable";
import {Country} from "@/models/Country";

export interface Address extends Idable<number> {
    type: AddressType;
    street?: string;
    number?: string;
    addition?: string;
    postalCode?: string;
    city?: string;
    country?: Country
}

export enum AddressType {
    OFFICIAL, POSTAL
}
