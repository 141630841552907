import {GenericApi, WrapperResponse} from "@/api/generic";
import {ShippingBox} from "@/models/ShippingBox";
import {get, PathVariableMap, url} from '@/utils/http';
import {LabOrder} from '@/models/LabOrder';
import {LabMeasurement} from "@/models/LabMeasurement";

export class LabOrderApi extends GenericApi<LabOrder> {

    constructor() {
        super('/api/v1/lab-order');
    }

    public async shippingBoxes(id: number, pathVariables: PathVariableMap = {}) {
        const uri = new URL(url(`${this.path}/${id}/shipping-boxes`, pathVariables));
        const result = await get(uri.href);
        return result.data as WrapperResponse<ShippingBox[]>;
    }

    public async labMeasurements(id: number, pathVariables: PathVariableMap = {}) {
        const uri = new URL(url(`${this.path}/${id}/lab-measurements`, pathVariables));
        const result = await get(uri.href);
        return result.data as WrapperResponse<LabMeasurement[]>;
    }
}
