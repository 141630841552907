import {Idable} from "@/models/Idable";
import {Address} from "@/models/Address";
import {Dealer} from "@/models/Dealer";
import {Ubn} from "@/models/Ubn";
import {Timestampable} from "@/models/Timestampable";

export enum CustomerDataSource {
    RVO_HOLDERS = 1,
    RVO_ME = 2,
}

export interface Customer extends Idable<number>, Timestampable {
    name?: string;
    brs?: string;
    coc?: string;
    ubns?: Ubn[];
    externalId?: string;
    address?: Address;
    postalAddress?: Address;
    email?: string;
    billingEmail?: string;
    phone?: string;
    mobile?: string;
    dealer?: Dealer;
    lavetanId?: string;
    comments?: string;
    isExistingCustomer?: boolean;
    dataSource?: CustomerDataSource;
}
