import {MessageHistoryItem, ShowsMessagesDataObject} from '@/composables/ShowsMessages';

export interface MessagesState {
    messages: ShowsMessagesDataObject[],
    historicMessages: MessageHistoryItem[],
}

const MessageModule = {
    namespaced: true,
    state: (): MessagesState => ({
        messages: [] as ShowsMessagesDataObject[],
        historicMessages: [] as MessageHistoryItem[],
    }),
    mutations: {
        clearMessages(state: MessagesState) {
            state.messages = [];
        },
        addMessage(state: MessagesState, message: ShowsMessagesDataObject) {
            state.messages = [...state.messages, message];
        },
        removeMessage(state: MessagesState, message: ShowsMessagesDataObject) {
            const idx = state.messages.findIndex((elem) => elem === message);
            if (idx !== -1) {
                state.messages.splice(idx, 1);
                state.messages = [...state.messages];
            }
        },
        popMessage(state: MessagesState) {
            if (state.messages.length) {
                state.messages = [...state.messages.slice(1)];
            }
        },
    },
    action: {},
};

export {
    MessageModule,
};
