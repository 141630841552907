export enum LabProcessStep {
    INITIAL = 0,
    SHIPPING_BOX_SELECTION = 1,
    SHIPPING_BOX_CREATED = 2,
    NEW_SUBMISSION = 3,
    NEW_SUBMISSION_SCAN = 4,
    CUSTOMER_MANUAL_SEARCH = 5,
    CUSTOMER_SEARCH_RESULTS = 6,
    CUSTOMER_CONFIRMATION = 7,
    CUSTOMER_CONFIRMATION_EDIT = 8,
    SUBMISSION_OVERVIEW = 9,
    SHIPPING_BOX_COMPLETION = 10,
    SHIPPING_BOX_DETAILS = 11,
    SUBMISSION_DETAILS = 12,
    RESTART_FLOW = 13
}
