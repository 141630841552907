import {WrapperResponse} from '@/api/generic';
import {User} from '@/models/User';
import {get, post, url} from '@/utils/http';

/**
 * Api Accessor for Authentication
 */
export class AuthenticationApi {
    public async login(username: string, password: string, wsKey: string | null = null, wsName: string | null = null) {
        const body = {username, password};
        const result = await post(url('login'), body);
        return result.data as WrapperResponse<User>;
    }

    public async currentUser() {
        try {
            const result = await get(url('api/v1/current-user'));
            if (result && result.status == 200) {
                return result.data as WrapperResponse<User>;
            }
        } catch (error) {
            // ignore error, just return null;
        }

        return null;
    }

    public async logout() {
        await get(url('logout'));
    }
}
