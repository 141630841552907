import {GenericApi, WrapperResponse} from "@/api/generic";
import {Customer} from "@/models/Customer";
import {get, url} from "@/utils/http";
import {Livestock} from "@/models/Livestock";

export class LivestockApi extends GenericApi<Customer> {

    constructor() {
        super('/api/v1/livestock');
    }

    public async search(customer: Customer, number: string, defaultUbn?: string, defaultDob?:string) {
        const uri = new URL(url(this.path + '/search'));
        const searchParams = uri.searchParams;
        searchParams.append('number', number);
        searchParams.append('customerId', customer.id?.toString() ?? '');
        if (defaultUbn) {
            searchParams.append('ubn', defaultUbn);
        }
        if (defaultDob) {
            searchParams.append('dob', defaultDob);
        }
        const result = await get(uri.href);
        return result.data as WrapperResponse<Livestock>;
    }
}
