import {AuthenticationApi} from '@/api/auth';
import {GenericApi} from "@/api/generic";
import {User} from "@/models/User";
import {Role} from "@/models/Role";
import {ShippingBoxApi} from "@/api/shippingbox";
import {CustomerApi} from "@/api/customer";
import {LabMeasurement} from "@/models/LabMeasurement";
import {ShippingBoxPosition} from "@/models/ShippingBoxPosition";
import {LivestockApi} from "@/api/livestock";
import {LabOrderApi} from '@/api/laborder';
import {ConfigApi} from "@/api/config";

/**
 * Grouping class for all APIs
 */
export class Api {
    public readonly auth: AuthenticationApi;
    public readonly user: GenericApi<User>;
    public readonly role: GenericApi<Role>;
    public readonly shippingBox: ShippingBoxApi;
    public readonly shippingBoxPosition: GenericApi<ShippingBoxPosition>;
    public readonly customer: CustomerApi;
    public readonly livestock: LivestockApi;
    public readonly labOrder: LabOrderApi;
    public readonly labMeasurement: GenericApi<LabMeasurement>;
    public readonly config: ConfigApi;

    constructor() {
        this.auth = new AuthenticationApi();
        this.user = new GenericApi<User>('/api/v1/user');
        this.role = new GenericApi<Role>('/api/v1/role');
        this.shippingBox = new ShippingBoxApi();
        this.shippingBoxPosition = new GenericApi<ShippingBoxPosition>('/api/v1/shipping-box-position');
        this.customer = new CustomerApi();
        this.livestock = new LivestockApi();
        this.labOrder = new LabOrderApi();
        this.labMeasurement = new GenericApi<LabMeasurement>('/api/v1/lab-measurement');
        this.config = new ConfigApi();
    }
}

export function useApi() {
    return {
        api: new Api(),
    };
}
