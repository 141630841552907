export class LivestockNumber {
    public readonly country: string;
    public readonly number: string;
    public readonly original?: string;

    constructor(country: string, number: string, original?: string) {
        this.country = country;
        this.number = number;
        this.original = original;
    }

    static parseNumber(livestockNumber: string): LivestockNumber | null {
        const pattern = new RegExp("^([a-zA-Z]{2,3})?([0-9]{4,12})$");
        const matcher = pattern.exec(livestockNumber);
        if (!matcher) {
            return null;
        }

        const country = matcher[1];
        const number = matcher[2];

        return new LivestockNumber(country ? country : "NL", number, livestockNumber);
    }

    toString(): string {
        return this.country + this.number;
    }
}
