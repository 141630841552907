import {User} from '@/models/User';
import {Commit} from 'vuex';

export interface AuthenticationState {
    authenticated: boolean;
    authenticatedUser: User | null;
}

const AuthenticationModule = {
    namespaced: true,
    state: (): AuthenticationState => ({
        authenticated: false,
        authenticatedUser: null,
    }),
    mutations: {
        setAuthenticated(state: AuthenticationState, auth: boolean) {
            state.authenticated = auth;
        },
        setAuthenticatedUser(state: AuthenticationState, user: User | null) {
            state.authenticatedUser = user;
        },
    },
    actions: {
        async logout({commit}: { commit: Commit }) {
            for (const key of Object.keys(localStorage)) {
                if (key.indexOf('.') >= 0 && !key.startsWith('global.')) {
                    localStorage.removeItem(key);
                }
            }

            commit('setAuthenticated', false);
            commit('setAuthenticatedUser', null);
        },
    },
};

export {
    AuthenticationModule,
};
