// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Vuetify
import {createVuetify} from 'vuetify';

export default createVuetify({
    theme: {
        themes: {
            light: {
                colors: {
                    success: "#4CAF50",
                    warning: "#FB8C00",
                    error: "#B00020",
                    primary: '#f39200',
                    secondary: "#03DAC6",
                    info: "#2196F3",
                    surface: "#FFFFFF",
                    'primary-darken-1': "#3700B3",
                    'secondary-darken-1': "#018786",
                    'surface-variant': "#424242",
                },
            },
        },
    },
});
