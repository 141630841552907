import {$t} from '@/plugins/i18n';
import _isNumber from 'lodash/isNumber';
import _isEmpty from 'lodash/isEmpty';

export function minLength(size: number) {
    return (value: string) => (value && value.length < size) ?
        $t('validation.minLength', {size}) : true;
}

export function min(minValue: number) {
    return (value: string) => (value != null && (parseFloat(value)) < minValue) ?
        $t('validation.min', {minValue}) : true;
}

export function maxLength(size: number) {
    return (value: string) => (value && value.length > size) ?
        $t('validation.maxLength', {size}) : true;
}

export function max(maxValue: number) {
    return (value: string) => (value != null && (parseFloat(value)) > maxValue) ?
        $t('validation.max', {maxValue}) : true;
}

export function required(value: string) {
    let empty = false;
    if (value == null) {
        empty = true;
    } else if (_isNumber(value)) {
        empty = (value as unknown as number) === 0;
    } else if (_isEmpty(value)) {
        empty = true;
    }
    return empty ? $t('validation.required') : true;
}

export function passwordComplexity(value: string) {
    const matches = value && value.match(/[a-z]/) && value.match(/[A-Z]/)
        && value.match(/[0-9]/) && value.match(/[~!@#$%^&*()_+=[\]{}|:;"`',<.>«»/? -]/);

    return (!value || matches) ? true : $t('validation.passwordComplexity');
}

export function requiredNumber(value: string) {
    let empty = false;
    if (value == null || value === '' || value === '-') {
        empty = true;
    }
    return empty ? $t('validation.required') : true;
}

export function number(value: string) {
    return _isNumber(value) || !value ? true : $t('validation.number');
}

export function numberString(value: string) {
    return /^-?\d+(\.\d+)?$/.test(value) || value === '' || value == null ? true : $t('validation.number');
}

export function containedInSet<T>(set: T[]) {
    return (value: T) => value != null && !set.includes(value) ? $t('validation.notInSet') : true;
}
