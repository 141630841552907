import {Router} from 'vue-router';
import {loadI18nMessages} from '@/plugins/i18n';
import {Store} from 'vuex';
import {hasAuthority, hasRole} from '@/utils/auth';
import {State} from '@/store';

export function authenticateRouter(router: Router, store: Store<State>) {
    router.beforeEach((to, from, next) => {
        const lang = (to.query.lang && Array.isArray(to.query.lang) ? to.query.lang[0] : to.query.lang)
            || localStorage.getItem('locale') || 'nl';
        loadI18nMessages(lang).then(() => {
            const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
            const requiredRoles = to.matched.flatMap((record) => record.meta.roles).filter((t) => !!t) as string[];
            store.commit('setRouteTitle', to.meta.title);
            if (requiresAuth && !store.state.authentication.authenticated) {
                next('/login');
            } else {
                if (requiresAuth && requiredRoles && requiredRoles.length > 0) {
                    const user = store.state.authentication.authenticatedUser;
                    const isAllowed = requiredRoles.filter((role: string) => !!role)
                        .reduce((acc, curr) => {
                            return acc || hasAuthority(user, curr) || hasRole(user, curr);
                        }, false);
                    if (!isAllowed) {
                        next({path: 'access-denied', replace: true});
                    } else {
                        next();
                    }
                } else {
                    next();
                }
            }
        });
    });
}
