
export interface IWebsocketMessage {
    messageType: MessageType;
    recipientChannel: string;
    value: string;
}

export enum MessageType {
    SUBSCRIBE = 1, UNSUBSCRIBE = 2, POST_WORKSTATION = 3, CLIENT_INFO_REQUEST = 4, CLIENT_INFO_RESPONSE = 5,
}
