import axios from 'axios';
import settings from '@/settings';
import _template from 'lodash/template';
import _isEmpty from 'lodash/isEmpty';

const http = axios.create({withCredentials: true});

function get(url: string) {
    return http.get(url);
}

function post<T>(url: string, body: T) {
    return http.post(url, body);
}

function remove(url: string) {
    return http.delete(url);
}

function put<T>(url: string, body: T) {
    return http.put(url, body);
}

function patch<T>(url: string, body: T) {
    return http.patch(url, body);
}

function makeUrl(path: string, pathVariables?: PathVariableMap) {
    if (!_isEmpty(pathVariables)) {
        const template = _template(path, {interpolate: /{([\s\S]+?)}/g});
        path = template(pathVariables);
    }

    if (path.includes('{')) {
        throw new Error('Url appears to still contain variables: ' + path);
    }

    return settings.API_URL + '/' + path.replace(/^\/+/, '');
}

interface PathVariableMap {
    [key: string]: string | number;
}


/**
 * Merges search parameters into specific parameter set.
 * @param target target to merge into
 * @param params the source URLSearchParams
 */
function mergeURLSearchParams(target: URLSearchParams, ...params: URLSearchParams[]): URLSearchParams {
    const result = target;
    params.forEach((p: URLSearchParams) => {
        p.forEach((value: string, key: string) => {
            result.append(key, value);
        });
    });
    return result;
}

export {
    http,
    makeUrl as url,
    mergeURLSearchParams,
    get,
    post,
    put,
    patch,
    remove,
    PathVariableMap,
};
