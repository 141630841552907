import {Store, useStore} from 'vuex';
import _isObject from 'lodash/isObject';
import _isNumber from 'lodash/isNumber';
import _isNil from 'lodash/isNil';
import {AxiosError, AxiosResponse} from 'axios';
import {useI18n} from 'vue-i18n';
import {key, State} from '@/store';
import {$t as t} from '@/plugins/i18n';

export interface ShowsMessagesDataObject {
    done: boolean;
    message: string;
    timeout: number;
    error: boolean;
    timeoutProgress?: number;
    multiline?: boolean;
    id: string;
    animated?: boolean;
    animationTimeout?: number;
}

export const MESSAGE_HISTORY_LOCAL_STORAGE_KEY = 'msgHistory';

export interface MessageHistoryItem {
    message: ShowsMessagesDataObject;
    timestamp: number;
}

type MessageSettings = number | { timeout?: number; id?: string; error?: boolean };

type ErrorType = Error | AxiosError;

export class MessageService {
    constructor(private store: Store<State>, private $t = t) {
    }

    public clearMessages = () => {
        this.store.commit('messages/clearMessages');
    };

    public popMessage() {
        this.store.commit('messages/popMessage');
    }

    public showMessage(message: string, timeoutOrSettings: MessageSettings = {timeout: 0}) {
        this.addMessage(message, timeoutOrSettings);
    }

    public showError(message: string, timeoutOrSettings: MessageSettings = {timeout: 0}) {
        if (_isNumber(timeoutOrSettings) || _isNil(timeoutOrSettings)) {
            timeoutOrSettings = {timeout: timeoutOrSettings ?? 0};
        }
        this.addMessage(message, Object.assign(timeoutOrSettings, {error: true}));
    }

    public showErrorByException(e: ErrorType, fallbackMessageKey: string, timeout: MessageSettings = {timeout: 0}) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((window as any).webpackHotUpdate) {
            console.error(e);
        }
        let errorMessage = this.$t(fallbackMessageKey);
        if (e instanceof AxiosError && e.response && e.response.data && e.response.status === 400) {
            const response = e.response as AxiosResponse;
            if (typeof response.data.error === 'string' && response.data.error.indexOf(' ') === -1) {
                errorMessage = this.$t('validation.' + response.data.error);
            } else if (typeof response.data.data.error === 'string' && response.data.data.error.indexOf(' ') === -1) {
                errorMessage = this.$t('validation.' + response.data.data.error);
            } else if (typeof response.data.error === 'object' && response.data.error.error) {
                errorMessage = this.$t('validation.' + response.data.error.error, response.data.error.arguments);
            } else if (typeof response.data.data.error === 'object' && response.data.data.error.error) {
                errorMessage = this.$t('validation.' + response.data.data.error.error, response.data.data.error.arguments);
            }
        } else if (e instanceof AxiosError && e.response && e.response.data && e.response.status === 409) {
            errorMessage = this.$t('message.etagConflict');
        } else if (e instanceof AxiosError && e.response && e.response.data && e.response.status === 500) {
            const response = e.response as AxiosResponse;
            if (typeof response.data.data.error === 'string' && response.data.data.error.indexOf(' ') === -1) {
                errorMessage = this.$t('error.' + response.data.data.error, response.data.data.arguments);
            }
        }
        this.showError(errorMessage as string, timeout);
    }

    public addMessage(message: string, timeoutOrSettings: MessageSettings = {timeout: 0}) {
        const match = this.store.state.messages.messages.find(
            (mo: ShowsMessagesDataObject) => mo.message === message && !mo.done);

        if (match) {
            if (match.timeoutProgress != null) {
                match.timeoutProgress = 0;
                match.timeout = (_isObject(timeoutOrSettings) ? timeoutOrSettings.timeout : timeoutOrSettings)
                    || match.timeout;
                match.animated = false;
                if (match.timeout !== -1) {
                    setTimeout(() => {
                        match.animated = true;
                        clearTimeout(match.animationTimeout);
                        match.animationTimeout = setTimeout(() => {
                            match.animated = false;
                        }, match.timeout);
                    });
                }
            }
            return; // Do not add message if exact same message was added before and is yet to be displayed.
        }

        const error = _isObject(timeoutOrSettings) ? timeoutOrSettings.error ?? false : false;
        const timeout = _isObject(timeoutOrSettings) ? timeoutOrSettings.timeout : timeoutOrSettings;
        const id = _isObject(timeoutOrSettings) ? timeoutOrSettings.id ?? null : null;
        const messageObject = {done: false, error, message, timeout: timeout || 20000, id: id || this.generateId()};
        this.store.commit('messages/addMessage', messageObject);
    }

    public clearMessage(idOrMessage: string, isMessage?: boolean) {
        const match = this.store.state.messages.messages.find(
            (message: ShowsMessagesDataObject) => isMessage ? message.message === idOrMessage : message.id === idOrMessage);
        if (match) {
            match.timeoutProgress = match.timeout;
            match.done = true;
        }
    }

    private generateId() {
        return Math.random().toString().substring(2, 8);
    }
}

export function useMessages() {
    const store = useStore(key);
    const {t: $t} = useI18n();

    const msgs = new MessageService(store, $t);

    return {
        clearMessages: msgs.clearMessages.bind(msgs),
        popMessage: msgs.popMessage.bind(msgs),
        showMessage: msgs.showMessage.bind(msgs),
        clearMessage: msgs.clearMessage.bind(msgs),
        showError: msgs.showError.bind(msgs),
        showErrorByException: msgs.showErrorByException.bind(msgs),
    };
}
