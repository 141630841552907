import {useApi} from '@/api';
import {Customer} from '@/models/Customer';
import {onMounted, Ref, ref, watch} from 'vue';

export function usePaymentStatus(customer: Ref<Customer | null>) {
    const {api} = useApi();

    const paymentStatus = ref(null) as Ref<boolean | null>;

    async function getPaymentStatus(customerId: number) {
        try {
            const result = await api.customer.paymentStatus(customerId);

            return result.data;
        } catch (e) {
            return null;
        }
    }

    async function refresh() {
        const cust = customer.value;
        if (cust?.id) {
            paymentStatus.value = await getPaymentStatus(cust.id);
        }
    }

    watch(customer, async (customer) => {
        if (customer?.id) {
            paymentStatus.value = await getPaymentStatus(customer.id);
        }
    });

    onMounted(async () => {
        await refresh();
    });

    return {
        getPaymentStatus,
        paymentStatus,
        refresh,
    };
}
