import {createStore, Store} from 'vuex';
import {InjectionKey} from 'vue';
import {MessageModule, MessagesState} from '@/store/messages';
import {AuthenticationModule, AuthenticationState} from '@/store/authentication';

// define your typings for the store state
export interface State {
    routeTitle?: string;
    messages: MessagesState;
    authentication: AuthenticationState;
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol();

export default createStore<State>({
    state: {} as State,
    getters: {},
    mutations: {
        setRouteTitle(state: State, routeTitle?: string) {
            state.routeTitle = routeTitle;
        },
    },
    actions: {},
    modules: {
        messages: MessageModule,
        authentication: AuthenticationModule,
    },
});
