import {createRouter, createWebHistory, RouteRecordRaw, RouterView} from 'vue-router';
import LoginPage from '../views/LoginPage.vue';
import DashboardPage from '../views/DashboardPage.vue';
import LabProcessFlowPage from '../views/labprocess/LabProcessFlowPage.vue';
import LabSubmissionsPage from '../views/labsubmissions/LabSubmissionsPage.vue';
import LabSubmissionViewPage from '../views/labsubmissions/LabSubmissionViewPage.vue';
import UserListPage from '../views/users/UserListPage.vue';
import UserEditPage from '../views/users/UserEditPage.vue';
import WorkstationLinkPage from '../views/workstation/WorkstationLinkPage.vue';
import {h} from 'vue';
import {$t} from '@/plugins/i18n';

const AccessDeniedPage = () => import('../views/AccessDeniedPage.vue');

const routes: Array<RouteRecordRaw> = [
    {
        path: '/workstation',
        name: 'workstation',
        component: WorkstationLinkPage,
    },
    {
        path: '/',
        name: 'dashboard',
        component: DashboardPage,
        meta: {requiresAuth: true, roles: ['ROLE_ADMIN']},
        children: [
            {
                path: '/users',
                name: 'users',
                component: {
                    render: () => h(RouterView),
                },
                children: [
                    {
                        path: '',
                        name: 'userList',
                        components: {
                            default: UserListPage,
                        },
                        meta: {title: $t('DashboardPage.navigation.users')},
                    },
                    {
                        path: '/users/new',
                        name: 'userCreate',
                        components: {
                            default: UserEditPage,
                        },
                        meta: {title: $t('DashboardPage.navigation.users') + ' — ' + $t('message.new')},
                    },
                    {
                        path: '/users/:id',
                        name: 'userEdit',
                        components: {
                            default: UserEditPage,
                        },
                        meta: {title: $t('DashboardPage.navigation.users') + ' — ' + $t('message.edit')},
                    },
                ],
            },
            {
                path: '/lab-process',
                name: 'labprocess',
                meta: {title: $t('DashboardPage.navigation.labProcess')},
                components: {
                    default: LabProcessFlowPage,
                },
            },
            {
                path: '/lab-submissions',
                name: 'labSubmissions',
                component: {
                    render: () => h(RouterView),
                },
                children: [
                    {
                        path: '',
                        name: 'labSubmissionsList',
                        meta: {title: $t('DashboardPage.navigation.labSubmissions')},
                        components: {
                            default: LabSubmissionsPage,
                        },
                    },
                    {
                        path: '/lab-submissions/view/:id',
                        name: 'labSubmissionView',
                        components: {
                            default: LabSubmissionViewPage,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
    },
    {
        path: '/access-denied',
        name: 'access-denied',
        component: AccessDeniedPage,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
