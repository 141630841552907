import {GenericApi, WrapperResponse} from "@/api/generic";
import {Customer} from "@/models/Customer";
import {get, post, url} from '@/utils/http';

export class CustomerApi extends GenericApi<Customer> {

    constructor() {
        super('/api/v1/customer');
    }

    public async search(query: string, field: 'number' | 'name' | 'ubn') {
        const uri = new URL(url(this.path + '/search'));
        const searchParams = uri.searchParams;
        searchParams.append(field, query);
        const result = await get(uri.href);
        return result.data as WrapperResponse<Customer[]>;
    }

    public async paymentStatus(customerId: number) {
        const uri = new URL(url(`${this.path}/${customerId}/payment-status`));
        const result = await get(uri.href);
        return result.data as WrapperResponse<boolean | null>;
    }

    public async exportToCash(customerId: number) {
        const uri = new URL(url(`${this.path}/${customerId}/export-to-cash`));
        const result = await post(uri.href, null);
        return result.data as WrapperResponse<{ success: boolean | null, transactionId: string }>;
    }
}
