import {Role} from '@/models/Role';
import {User} from '@/models/User';

export function hasAuthority(user: User | null, name: string): boolean {
    return user != null && user.roles != null
        && user.roles.find((t: Role) => t && t.code === name.toLowerCase()) != null;
}

export function hasRole(user: User | null, name: string): boolean {
    return name != null && name.toLowerCase().startsWith('role_') && user != null && user.roles != null
        && user.roles.find((t: Role) => t && 'role_' + t.code.toLowerCase() === name.toLowerCase()) != null;
}

export function hasAnyRole(user: User | null, name: string[]): boolean {
    return user != null && user.roles != null && name.reduce((acc: boolean, curr: string) => {
        return acc || hasRole(user, curr);
    }, false);
}

export function hasAnyAuthority(user: User | null, name: string[]): boolean {
    return user != null && user.roles != null && name.reduce((acc: boolean, curr: string) => {
        return acc || hasAuthority(user, curr);
    }, false);
}

